import Slider from "./components/Slider";
import "./components/datepicker.js";
import {DeparturePicker} from "./components/departure-picker.js";
import inputPopover from "./components/input-popover.js";
import {FormGuide, FormGuideDefaults} from "./components/form-guide";
import {InputGuests} from "./components/input-guests";
import functions from "./functions";
import { Select2 } from "./components/select2";

$(function () {

  $('[name="offerType"]').on('change', e => {
    const $target = $(e.currentTarget);
    let offerType = $target.val();
    
    const $tabs = $('#searchHomeTab');
    let $tab = $tabs.find('[id="' + offerType + '"]');
    $tab.tab('show');
    
  });

  $('.btn-level').on('click', e => {
    e.preventDefault();
    e.stopPropagation();
    const $target = $(e.currentTarget);
    $target.toggleClass('show', ! $target.hasClass('show'));
    
    const $sibling = $target.siblings('.dropdown-submenu');
    $sibling.toggleClass('show', ! $sibling.hasClass('show'));
  })

  $(".select2").each((i, elem) => {
    elem._select2 = new Select2(elem);
  });
  
  $('.form-guide').each((i, elem) => {
    elem._formGuide = new FormGuide(elem);
  });

  $('.datepicker.departure-picker [data-input]').each((i, elem) => {
    elem._departurePicker = new DeparturePicker(elem);
  });

  $('.input-guests').each((i, elem) => {
    elem._inputGuests = new InputGuests(elem);
  });

  inputPopover.apply();

  $('.search-form label').on('click', e => {
    const $target = $(e.currentTarget);
    const $elem = $target.find('.select2-search__field');
    $elem[0] && $elem.focus();
  });

  $(document).on('click', '[data-scroll-to]', e => {
    e.preventDefault();
    const $this = $(e.target);
    let scrollTo = $this.attr('data-scroll-to').split(',');
    functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
  });

  $('#collapseItinerary').on('show.bs.collapse', e => {
      $('.show-itinerary').addClass('d-none');
  });
  $('#collapseItinerary').on('hide.bs.collapse', e => {
      $('.show-itinerary').removeClass('d-none');
  });

  $('#collapseMeetPoints').on('show.bs.collapse', e => {
      $('.show-points').addClass('d-none');
  });
  $('#collapseMeetPoints').on('hide.bs.collapse', e => {
      $('.show-points').removeClass('d-none');
  });

  if($('#hero-slider')[0]) {
    new Slider($('#hero-slider'), {
      rubberband: false,
      loop: true,
      arrows: true,
      pager: true,
      autoplay: 6000,
      slides: {
        perView: 1,
        spacing: 0,
      },
      defaultAnimation: {
        duration: 1200,
      },
    });
  }

  let pagesSlider = [];

  if($('[id^=pages-slider]')[0]) {
    $('[id^=pages-slider]').each((i, elem) => {
      pagesSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: true,
        arrows: true,
        pager: false,
        slides: {
          perView: 4,
          spacing: 20,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 1390px)': {
            slides: {
              perView: 3,
              spacing: 20,
            },
          },
          '(max-width: 768px)': {
            slides: {
              perView: 2.2,
              spacing: 20,
            },
          },
          '(max-width: 560px)': {
            slides: {
              perView: 1.2,
              spacing: 20,
            },
          },
        }
      });
    });
  }

  let objectsSlider = [];

  if($('[id^=objects-slider]')[0]) {
    $('[id^=objects-slider]').each((i, elem) => {
      objectsSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: true,
        arrows: false,
        pager: true,
        slides: {
          perView: 4,
          spacing: 0,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 1390px)': {
            slides: {
              perView: 3,
            },
          },
          '(max-width: 768px)': {
            slides: {
              perView: 2.2,
            },
          },
          '(max-width: 560px)': {
            slides: {
              perView: 1.2,
            },
          },
        }
      });
    });
  }

  if(functions.getCookie('cookie_usage') !== 'accepted') {
    $("#cookie-bar").removeClass('d-none');
  }

  $('#cookie-bar-accept').on('click', function (e) {
    functions.setCookie('cookie_usage', 'accepted');
    $("#cookie-bar").addClass('d-none');
  });

  $('.show-more').each((i, elem) =>
    {
        let $showMore = $(elem);

        let height = $showMore.attr('data-height');
        if ($showMore.find('.show-more-content').outerHeight() < height)
        {
            $showMore.addClass('removeBefore');
            $showMore.next('.show-more-expand').remove();
            return;
        }

        let $breakpoint = $showMore.find('.show-more-breakpoint');
        if (height)
        {
            $showMore.css('max-height', +height);
        }
        else if ($breakpoint.length) 
        {
            $showMore.css('max-height', $breakpoint.offset().top - $showMore.offset().top - 25);
        }
        else 
        {
            let $maxHeight = functions.isMobile() ?
                ($showMore.outerHeight() * 0.15) :
                ($showMore.outerHeight() * 0.3);

            $showMore.css('max-height', $maxHeight);
        }

        $showMore.next('.show-more-expand').on('click', e =>
        {
            let $prevSibling = $(e.currentTarget).prev();
            let $maxHeight
            if ($prevSibling.hasClass('removeBefore'))
            {
                let height = $prevSibling.attr('data-height');
                let $breakpoint = $prevSibling.find('.show-more-breakpoint');
                if (height)
                {
                    $maxHeight = +height;
                }
                else if ($breakpoint.length) 
                {
                    $maxHeight = $breakpoint.offset().top - $showMore.offset().top - 25;
                }
                else 
                {
                    $maxHeight = functions.isMobile() ?
                        ($prevSibling.outerHeight() * 0.15) :
                        ($prevSibling.outerHeight() * 0.3);
                }
                $prevSibling.css('max-height', $maxHeight);
                $prevSibling.removeClass('removeBefore');
                if ($prevSibling.hasClass('show-more-scroll')) 
                {
                    $('html, body').stop().animate({
                        scrollTop: $prevSibling.offset().top + $maxHeight - $(e.target).outerHeight(),
                    }, 0, 'linear');
                }
            }
            else 
            {
                let $content = $prevSibling.find('.show-more-content');
                $prevSibling.css('max-height', $content.outerHeight() + 50);
                $prevSibling.addClass('removeBefore');
            }
        })
    });

  functions.favoritesLabel();

  const $wpGallerys = $('.wp-block-gallery');
  $wpGallerys.each((index, wpGallery) => {
    let $wpGallery = $(wpGallery);
    const $wpGalleryLinks = $wpGallery.find('.wp-block-image');
    const wpGalleryArray = $wpGalleryLinks.map((i, elem) =>
    {
        const $elem = $(elem);
        const $img = $elem.find('img');
        let imgs = $img.attr('src').split(', ');
        // let imgs = $img.attr('srcset').split(', ');
        let sizes = $.map(imgs, img =>
        {
            let size = img.match(/(\d+)w$/);
            return size ? +size[1] : 9999;
        });
        let minSize = sizes.indexOf(Math.min.apply(Math, sizes));
        minSize = minSize > -1 ? minSize : 0;

        let img = imgs[minSize].replace(/ \d+w$/, '');
        return {
            src: $img.attr('src'),
            thumb: img
        };
    });

    $wpGallery.on('click', '.wp-block-image', e =>
    {
        e.preventDefault();
        let index = $wpGalleryLinks.index(e.currentTarget);

        if ($(e).hasClass('lg-on'))
        {
            $(e).data('lightGallery').index = index;
            $(e).data('lightGallery').build(index);
        } else
        {
            $(e).lightGallery({
                dynamic: true,
                thumbnail: true,
                toogleThumb: false,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                autoplay: false,
                preload: 2,
                index: index,
                dynamicEl: wpGalleryArray
            }).addClass('lg-on');
        }
    });

    $wpGallery.each((i, elem) =>
    {
        const $links = $(elem).find('.wp-block-image');
        let eq = $links.length >= 5 ? 4 : $links.length - 1;
        $links.eq(eq).append(
            `<div class="see-all-images position-absolute bottom-0 end-0 px-2 py-3 d-lg-block d-none">
            <div class="w-auto btn-sm btn btn-warning text-white shadow text-white rounded fw-normal" data-gallery-id="gallery-section" data-index="0">
                Galerija (${wpGalleryArray.length})
            </div>
        </div>`
        );
        $links.eq(0).append(
            `<div class="see-all-images position-absolute bottom-0 end-0 px-2 py-3 d-lg-none d-block">
            <div class="w-auto btn-sm btn btn-warning text-white shadow text-white rounded fw-normal" data-gallery-id="gallery-section" data-index="0">
                Galerija (${wpGalleryArray.length})
            </div>
        </div>`
        );
    });
  })



	let $notificationModal = $('.notification-modal');

	let notificationCookie = functions.getCookie('notification-modal');

	if ( ! notificationCookie ) {
		setTimeout(() => {
			$notificationModal.addClass('show');

			$notificationModal.find('.close-modal').on('click', e => {
				functions.setCookie('notification-modal', true, .5);
				$notificationModal.removeClass('show');
			});
		}, 500);
	}

  if(functions.getCookie('cookie_usage') !== 'accepted') {
    $("#cookie-bar").removeClass('d-none');
  }

  $('#cookie-bar-accept').on('click', function (e) {
    functions.setCookie('cookie_usage', 'accepted');
    $("#cookie-bar").addClass('d-none');
  });

  $('.form-field').on('click', e =>
    {
      e.stopPropagation();
      let $elem = $(e.currentTarget);
        setTimeout(() => {
            if ($(e.target).hasClass('simple-guests-apply')) return;
            if ($(e.target).hasClass('btn-month')) return;
            if ($(e.target).hasClass('month')) return;
            if ($(e.target).hasClass('year')) return;
            if ($elem.find('.form-control.input-popover').length) 
            {
              $elem.find('.form-control.input-popover').addClass('show');
              $elem.find('.form-control.input-popover').attr('aria-expanded', 'true');
              $elem.find('.dropdown-menu.dropdown-menu-popover').addClass('show');
            }
            if ($elem.find('.datepicker-range').length) $elem.find('.datepicker-range')[0]._flatpickr.open();
            if ($elem.find('.input-guests').length) $elem.find('.input-guests').dropdown('show');
            if ($elem.find('.form-guide').length) $elem.find('.form-guide')[0]._formGuide.show();
        });
    });

});