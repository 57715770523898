import './scss/style.scss';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import flatpickr from "flatpickr";
flatpickr.localize(flatpickr.l10ns['sr']);
flatpickr.l10ns.default.firstDayOfWeek = 1;
import 'flatpickr/dist/l10n/sr.js';
require("flatpickr/dist/themes/airbnb.css");

import 'lightgallery/src/js/lightgallery.js';
import 'lightgallery/modules/lg-thumbnail.js';
import 'lightgallery/modules/lg-fullscreen.js';

import lozad from 'lozad';

import './js/components/gallery';
import './js/globals';
import './js/list';
import './js/details';
import './js/booking';
import './js/contact';
import './js/inquiry';
import './js/bookingAddGuests.js';
//import './js/contactRequest.js';

import './js/gutenberg/gutenberg.js';

window.observer = lozad('.lozad', {
    rootMargin: '200px 0px',
    loaded: el => {
        $(el).triggerHandler('lozadLoaded');
    }
});
window.observer.observe();

window.nobserver = lozad('.load-recaptcha', {
    rootMargin: '200px 0px',
    loaded: el => {
        $.getScript("https://www.google.com/recaptcha/api.js?render=" + '6LcaY1QqAAAAAFhJhUvsqpmlWpUgCaIc09Lhj_yW');
    }
});
window.nobserver.observe();