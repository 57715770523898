import functions from '../functions.js';
import { details } from './details';
import { DetailsCart } from './details-cart.js';
import Slider from "../components/Slider";

$(() => {
    detailsAccommodation.init();
})

const detailsAccommodation = {
    $details: null,
    $form: null,
    form: null,
    $cart: null,
    cart: [],
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    defaults: {
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 1,
        optionals: 'base',
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        
        if(! this.$details[0] || this.params.objectGroupId != 1) {
            return;
        }
        if(+this.$details.attr('data-dynamicpackage') != 0) {
            return;
        }

        this.$form = $('.availability-form');
        this.form = this.$form[0];

        this.params.objectId = this.$details.attr('data-objectid');
        this.params.id = this.$details.attr('data-id');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;
        this.params.isBrosTravel = $('[data-isBrosTravel]').length ? $('[data-isBrosTravel]').attr('data-isBrosTravel') : null;
        this.params.isBrosLive = $('[data-isBrosLive]').length ? $('[data-isBrosLive]').attr('data-isBrosLive') : null;

        this.getParams();
        if(! this.params.dates && this.params.dateFrom && this.params.dateTo) {
            this.params.dates = this.params.dateFrom + ' - ' + this.params.dateTo;
        }
        
        if(this.form) {
            functions.setFormData(this.form, this.params);
        }

        this.params.adultsOnly = $('.input-guests').attr('data-adultsonly');

        if(this.params.guests) {

            this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;

            $('[name=guests]').each((index, elem) => {
                $(elem).val(this.params.guests);

                let guests = this.params.guests.split(',');
                let i = 1;
                for (let index = 2; index < guests.length; index++) {
                    if(guests[index]) {
                        $('[name=child-age-'  + i + ']').val(guests[index]);
                    }
                    i++;
                }
            });
        }

        if(! this.params.serviceCode && $('[name="serviceCode"]').length) {
            this.params.serviceCode = $('[name="serviceCode"]').val();
        }

        if ($('.booking-calendar-wrapper').length) {
            $.post('/services/accommodation_availability_form_data/', {
                objectId: this.params.id,
                language: functions.getLang(),
            }).always(response => {
                response = response ? JSON.parse(response) : null;
                if (response.status) {

                    response.data.items = Object.values(response.data.items);

                    if (response.data.items.length) {
                        $(".booking-section").removeClass('d-none')

                        let departures = {};

                        response.data.items.forEach((rate) => {
                            let unique = rate.dateFrom + ',' + rate.dateTo;

                            if ( ! departures[unique])
                            {
                                let rates = {};
                                rates[rate.id] = rate;

                                const date1 = new Date(rate.dateFrom);
                                const date2 = new Date(rate.dateTo);
                                const diffTime = Math.abs(date2 - date1);
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                                departures[unique] = {
                                    'dateFrom'  : rate.dateFrom,
                                    'dateTo'    : rate.dateTo,
                                    'duration'  : rate.accommodationDuration,
                                    'rates'     : rates,
                                    'nights'    : diffDays
                                };
                            }
                            else
                            {
                                let departure = departures[unique];
                                departure.rates[rate.id] = rate;
                            }
                        });

                        this.departures = departures = Object.values(departures);

                        $("#details-datepicker").attr('data-departures', JSON.stringify(departures));

                        this.setDefaultDeparture(departures);
                        this.renderPeriods(departures);
                        this.initDefault();

                        functions.setFormData(this.form, this.params, false);
                        this.getCalculation();

                    } else {
                        $(".booking-section").addClass('d-none')
                        $(".unit-price-label").addClass('d-none')
                        $(".send-request-label").removeClass('d-none')
                    }
                }
            });

            $('[name=guests]').on('change', () => {
                const data = functions.getFormData(this.$form);
                if ( data.dateFrom && data.dateTo && data.guests ) {
                    this.$form.trigger('submit');
                }
            });

        } else {
            this.getCalculation();

            // check availability on form change (not only on btn click)
            $('[name="dates"], [name=guests], [name="pricelistId"], [name="serviceCode"], [name="optionals"]').on('change', () => {
                const data = functions.getFormData(this.$form);

                if ( data.dates && (data.dates.split(' - ')[1] || false) && data.guests ) {
                    this.$form.trigger('submit');
                }
            });
        }

        this.$form.on('submit', e => {
            e.preventDefault();
            let params = functions.getFormData($(e.currentTarget));
            this.params.rateId = null;
            this.params.unitId = null;
            functions.setUrlData(params, true, true);
        });



        this.$cart = $('.details-cart');
        this.cart = this.$cart.length ? new DetailsCart(this.params) : [];

        $(window).on('hashchange', e => {
            this.getParams();
            this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;
            this.getCalculation(this.params);
        });

        if(this.$cart.length) {
            $('.btn-booking').on('click', e => {
                e.preventDefault();
    
                if(! this.cart.cartItems.length) {
                    $('#detailsCartModal').modal('show');
                    setTimeout(() => {
                        $('#detailsCartModal').modal('hide');
                    }, 2500);
                }
            });
        }

    },
    initDefault()
    {
        let $elem = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-duration=' + this.params.duration +']');

        if (! $elem.length) $elem = $('.departure[data-datefrom=' + this.params.dateFrom + ']');

        $elem.addClass('active');

        $elem.parent().addClass('active');

        this.params.dateFrom = $elem.attr('data-datefrom');
        this.params.dateTo = $elem.attr('data-dateto');
        this.params.duration = $elem.attr('data-duration') || '';

        functions.setUrlData(
            {
                'dateFrom': this.params.dateFrom,
                'dateTo': this.params.dateTo,
                'guests': this.params.guests,
                'duration': this.params.duration || ''
            },
            true,
            true);


        this.slider.instance.moveToIdx($elem.parent().index());
        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            $target.parent().addClass('active');

            this.params.dateFrom = $target.attr('data-datefrom');
            this.params.dateTo = $target.attr('data-dateto');
            this.params.duration = $target.attr('data-duration');

            $("[name='dateFrom']").val(this.params.dateFrom);
            $("[name='dateTo']").val(this.params.dateTo);

            this.printDates();

            functions.setUrlData(
                {
                    'dateFrom': this.params.dateFrom,
                    'dateTo': this.params.dateTo,
                    'guests': this.params.guests,
                    'duration': this.params.duration || ''
                },
                true,
                true);

        });
    },
    renderPeriods(departures) {
        let $calendarWraper = $('.booking-calendar-wrapper');
        this.dayAndMonthsData = $calendarWraper.attr('data-daysandmonths');
        this.dayAndMonthsData = this.dayAndMonthsData ? JSON.parse(this.dayAndMonthsData) : null;

        $calendarWraper.append(`<div id="booking-calendar-slider" class="keen-slider d-flex" style="z-index: 11;">`
            + ( departures.map((departure) => { return this.fillDepartureWithData(departure) }) ).join('')
            + `</div> <div class="keen-slider__arrows d-none">
                            <button class="btn btn-light bg-white rounded-circle p-2" data-dir="prev">
                                <i class="las la-angle-left text-muted la-lg"></i>
                            </button>
                            <button class="btn btn-light bg-white rounded-circle p-2" data-dir="next">
                                <i class="las la-angle-right text-muted la-lg"></i>
                            </button>
                        </div>` );

        this.initPeriodSlider($calendarWraper.find('#booking-calendar-slider'));
    },
    setDefaultDeparture(departures) {
        this.params.dateFrom = ! this.params.dateFrom && this.departures ? this.departures[Object.keys(this.departures)[0]].dateFrom : this.params.dateFrom;
        this.params.dateTo = ! this.params.dateTo && this.params.dateFrom && this.departures ? this.departures[Object.keys(this.departures)[0]].dateTo : this.params.dateTo;

        this.params.duration = ! this.params.duration && departures.length ? this.departures[Object.keys(this.departures)[0]].duration : this.params.duration;

        let departure = this.departures ? this.departures[this.params.dateFrom + ',' + this.params.dateTo] : null;
        departure && (this.params.time = departure.time);
    },
    initPeriodSlider($bookingCalendar)
    {
        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;

            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-12');
                $('#booking-calendar-slider').addClass('justify-content-xl-center');
                slidesPerView = 8;
                // $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 3));
                // $('.booking-calendar-wrapper').addClass('mx-auto');
            }


            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 10,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 4.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });



        }
    },
    getMonth(date) {
        let monthIndex = date.getMonth();
        return this.dayAndMonthsData[0][monthIndex+1];
    },
    getDay(date) {
        let dayIndex = date.getDay();
        let day = this.dayAndMonthsData[1][dayIndex+1];
        return day.charAt(0).toUpperCase() + day.slice(1);
    },
    fillDepartureWithData(departure) {
        let date = new Date(departure.dateFrom);
        let month = this.getMonth(date);
        let day = this.getDay(date);
        let monthShort = month.substring(0, 3);
        let year = date.getFullYear();
        let dayOfMonth = ('0' + date.getDate()).slice(-2);

        return `<div class="keen-slider__slide">
            <div class="departure pointer bg-white rounded m-2 mb-3 px-3 py-2 text-center position-relative"
                data-dateFrom="${ departure.dateFrom }"
                data-dateTo="${ departure.dateTo }"
                data-aDateFrom="${ departure.aDateFrom }"
                data-aDateTo="${ departure.aDateTo }"
                data-duration="${ departure.duration }"
                data-time="${ departure.time }">
                <div class="small text-muted"><small class="fw-semibold">${ year }</small></div>
                <div class="small text-muted"><small>${ day }</small></div>
                <div class="small mb-2 text-muted"><small>${ departure.nights } noćenja</small></div>
                <div class="mb-0 fw-semibold text-primary d-xl-block d-none">${ month }</div>
                <div class="mb-0 fw-semibold text-primary d-xl-none">${ monthShort }</div>
                <div class="h2 mt-2 mb-0 fw-semibold text-primary">${ dayOfMonth }</div>
            </div>
        </div>`
    },
    printDates() {
        $('.unit-departure').addClass('fade');
        setTimeout(() => {
            $('.unit-departure').removeClass('fade');
            $('.date-from').text(functions.formatDate(this.params.dateFrom));
            $('.date-to').text(functions.formatDate(this.params.dateTo));
            $('.overnights').text($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']').attr('data-duration'));
        }, 800);

    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        this.params.guests = this.params.guests && this.params.guests.startsWith('0') ? null : this.params.guests;
        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        }
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getCalculation(params) {
        const cParams = params ? params : this.params;

        $('.unit').find('.unit-price-total').html("");
        $('.unit').find('.unit-price-total-label').addClass('d-none');
        const calculation = details.getCalculation(cParams);
        
        if(calculation) {
            this.loader(cParams.unitId ? $('.unit[data-unitid=' + cParams.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response, cParams);
                }).fail(error => {
                    console.log(error);
                });
        } else {
            $('.unit-price-payment-key').toggleClass('d-none', false);

            //$('.unit-price-discount-title').toggleClass('d-none', false);
            //$('.btn-booking-container').toggleClass('d-none', false);

            this.params.isBrosTravel && $('.btn-booking').toggleClass('d-none', true);
            $('.add-to-cart').toggleClass('d-none', true);

            $('.no-period-label').toggleClass('d-none', false);
            $('.availablility-label').toggleClass('d-none', true);
            $('.unit-price').toggleClass('d-none', false);

            $('.unit-error-container').toggleClass('d-none', true);
            $('.unit-price-total-label').toggleClass('d-none', true);

            const optionals = cParams.optionals ? cParams.optionals : this.defaults.optionals;
            let serviceText = '';
            if(optionals && optionals != this.defaults.optionals) {
                const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
                allowedServices.forEach(item => {
                    if(item.supplementKey == optionals) {
                        serviceText = item.text;
                    }
                });
            }

            $('.unit').each((index, elem) => {
                const $elem = $(elem);

                const prices = $elem.attr('data-prices') ? JSON.parse($elem.attr('data-prices')) : null;
                const pricelistId = cParams.pricelistId ? cParams.pricelistId : $elem.attr('data-pricelistid');
                const item = prices ? (prices[pricelistId][optionals] ? prices[pricelistId][optionals] : prices[pricelistId]) : null;
                const pricelistsService = $elem.attr('data-pricelistsservice') ? JSON.parse($elem.attr('data-pricelistsservice')) : null;

                if(item) {
                    const $unitPriceTotal = $elem.find('.unit-price-total');
                    const $unitPriceOld = $elem.find('.unit-price-old');
                    const $unitPricePaymentKey = $elem.find('.unit-price-payment-key');
                    const $serviceSupplement = $elem.find('.service-supplement');
    
                    $unitPriceTotal.text(item.price != '€ 0' ? item.price : '');
                    $unitPriceOld.text(item.priceOld != '€ 0' ? item.priceOld : '');
                    $unitPriceOld.toggleClass('d-none', false);
                    $unitPricePaymentKey.text(item.paymentKey);
                    $serviceSupplement.text(pricelistsService[pricelistId] ? pricelistsService[pricelistId] : serviceText);
                }

                const order = $elem.attr('data-order');
                $elem.prop('style', 'order: ' + (order ? order : '10000'));
            })
        }
    },
    calculationDone(response, params) {

        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = this.params.rateId ? $('.arrangement-form[data-unitid=' + unitId + '][data-rateid=' + this.params.rateId + ']') : $('.unit[data-unitid=' + unitId + ']');
                const $btnBooking = $unit.find('.btn-booking');

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    let discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }
                    if(+priceOld.toFixed(2) > +total.toFixed(2)) {
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                    } else {
                        $unit.find('.unit-price-old').toggleClass('d-none', true);
                    }

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    let cartData = [];

                    cartData.push({
                        'unitId': unitId,
                        'dateFrom': this.params.dateFrom,
                        'dateTo': this.params.dateTo,
                        'guests': this.params.guests,
                    }); 

                    if ($btnBooking.attr('href')) {
                        $btnBooking.attr('href',
                            $btnBooking.attr('href').split('?')[0] +
                            '?unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests + '&addAllOptionals=true' 
                            + (this.params.serviceCode ? '&serviceCode=' + this.params.serviceCode : '')
                            + (this.params.pricelistId ? '&pricelistId=' + this.params.pricelistId : '')
                        );
                    }

                    if(this.$cart.length) {
                        // $addToCart.attr('data-unitcarttotal', total);
                        // $addToCart.attr('data-unitcarttotalold', priceOld);
                        // $addToCart.attr('data-currencysymbol', currencySymbol);
    
                        // $addToCart.attr('data-datefrom', params.dateFrom);
                        // $addToCart.attr('data-dateto', params.dateTo);
                        // $addToCart.attr('data-guests', params.guests);

                        // params.serviceCode && $addToCart.attr('data-servicecode', params.serviceCode);
                    } else {
                        const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                        $btnBooking.text(unitData.booking.status == 'Request' ? $btnBooking.attr('data-requestLabel') : $btnBooking.attr('data-bookingLabel'));

                        // $btnBooking.on('click', e => {
                        //     e.preventDefault();

                        //     let cartData = [
                        //         {
                        //             'unitId': unitId,
                        //             'dateFrom': params.dateFrom,
                        //             'dateTo': params.dateTo,
                        //             'guests': params.guests,
                        //             'pricelistId': params.pricelistId,
                        //             'serviceCode': params.serviceCode,
                        //             'optionals': params.optionals,
                        //         }
                        //     ];

                        //     location = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData);
                        // });
                    }
                    
                    //this.printDates();

                    $unit.prop('style', 'order: ' + parseInt(total));
                    
                } else {
                    $unit.prop('style', 'order: 10000');
                }

                //$unit.find('.unit-price-label').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-price-payment-key').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.fm-discount').toggleClass('d-none', details.isBookable(unitData));
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', details.isBookable(unitData));
                //$unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                //$unit.find('.no-period-label').toggleClass('d-none', details.isBookable(unitData));

                this.params.isBrosTravel && $unit.find('.btn-booking').toggleClass('d-none', ! details.isBookable(unitData));

                
                $btnBooking.length && $btnBooking.toggleClass('d-none', ! details.isBookable(unitData));

                //$unit.find('.btn-booking-container').toggleClass('d-none', ! details.isBookable(unitData));
                //$unit.find('.btn-request-nobookable').toggleClass('d-none', details.isBookable(unitData));

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);
                
                $unit.find('.availablility-label').toggleClass('d-none', unitData.message != 'notAvailable');

                if (this.params.isBrosLive)
                {
                    $unit.find('.no-period-label').toggleClass('d-none', true);
                    $unit.find('.bros-message').toggleClass('d-none', false);
                }
                else
                {
                    $unit.find('.no-period-label').toggleClass('d-none', true);
                }

                this.params.isBrosTravel && details.isBookable(unitData) && $unit.find('.bros-message').toggleClass('d-none', false);

                if(this.params.isBrosTravel && ! details.isBookable(unitData)) {
                    $unit.find('.availablility-label').toggleClass('d-none', true);
                    $unit.find('.bros-message').toggleClass('d-none', true);
                    $unit.find('.unit-error-container').toggleClass('d-none', false);
                    if(this.params.calcErrorMessages) {
                        $unit.find('.unit-error-container').text(this.params.calcErrorMessages.general);
                    }
                }

                $unit.find('.unit-price-label').toggleClass('d-none', true);
                params.pricelistId = params.pricelistId ? params.pricelistId : $unit.attr('data-pricelistid');
            });

            // if(this.$cart.length) {
            //     const $btnBooking = $('.btn-booking');
                
            //     $btnBooking.on('click', e => {
            //         e.preventDefault();
            //         let cartData = [];

            //         this.cart.cartItems.forEach(item => {
            //             item = JSON.parse(item);
            //             cartData.push({
            //                 'unitId': item.unitId,
            //                 'dateFrom': item.dateFrom,
            //                 'dateTo': item.dateTo,
            //                 'guests': item.guests,
            //                 'pricelistId': item.pricelistId,
            //                 'serviceCode': item.serviceCode,
            //                 'optionals': item.optionals,
            //             }); 
            //         });
            //         let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true';

            //         if(this.cart.cartItems.length) {
            //             location = url;
            //         } else {
            //             $('#detailsCartModal').modal('show');
            //             setTimeout(() => {
            //                 $('#detailsCartModal').modal('hide');
            //             }, 2500);
            //         }

            //         //location = url;
            //     });
            // }
        } else {
            $(".btn-booking").addClass('d-none');
            $(".availablility-label").removeClass('d-none');

            let error = details.getCalcErrorMessage(this.params.calcErrorMessages, {});
            $('.unit-error-container').removeClass('d-none');
            $('.unit-error-container').text(error);
        }
    },
    loader($target = null) {
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 800);
    }
};
