if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        RichText,
        InspectorControls
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
        CheckboxControl
    } = window.wp.components;

    const {
        useState
    } = window.wp.element;

    const { 
        useBlockProps 
    } = window.wp.blockEditor;

    registerBlockType('custom-gutenberg/tab-button', {
        // built-in attributes
        title: 'Tab Button',
        description: 'Tab',
        icon: 'slides',
        category: 'custom-tabs',
        example: {},
        parent: ['custom-gutenberg/nav-tabs'],

        // custom attributes
        attributes: {
            id: {
                type: 'string',
                default: '',
            },
            title: {
                type: 'string',
                default: '',
            },
            icon: {
                type: 'string',
                default: '',
            },
            icon2: {
                type: 'string',
                default: '',
            },
            active: {
                type: 'boolean',
                default: false,
            },
        },

        edit ({ attributes, setAttributes }) {

            const {
                id,
                title,
                icon,
                icon2,
                active
            } = attributes;

            // custom functions
            const [ isCheched, setChecked ] = useState( active ? active : false );

            setAttributes({active: isCheched});

            function onTitleChange (title) {
                setAttributes({title: title});
            };

            function onIconChange (icon) {
                setAttributes({icon: icon});
            };

            function onIcon2Change (icon2) {
                setAttributes({icon2: icon2});
            };

            function onIdChange (id) {
                setAttributes({id: id});
            };

            const blockProps = useBlockProps( {
                className: 'nav-link' + (active ? ' active' : ''),
            } );

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Tab settings' }>
                        <p><strong>ID must be unique</strong></p>
                        <TextControl value={ id } onChange={ onIdChange }></TextControl>

                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        
                        <p><strong>Icon</strong></p>
                        <TextControl value={ icon } onChange={ onIconChange }></TextControl>
                        
                        <p><strong>Icon 2</strong></p>
                        <TextControl value={ icon2 } onChange={ onIcon2Change }></TextControl>

                        <CheckboxControl
                            label="Is default active tab"
                            help="Only ONE tab can be default active!"
                            checked={ isCheched }
                            onChange={ setChecked  }
                        />
                    </PanelBody>
                </InspectorControls>,
                <button { ...blockProps } id={ id + "-tab"} data-bs-toggle="tab" data-bs-target={ "#" + id } type="button" role="tab">
                    <RichText 
                        key="editable" 
                        tagName="span" 
                        placeholder="Tab" 
                        value={ title }
                        onChange={ onTitleChange } />
                </button>
            ]);
        },

        save ({ attributes }) {

            const {
                id,
                title,
                active,
                icon,
                icon2
            } = attributes;

            return ( 
                <button className={ "nav-link" + (active ? ' active' : '' ) } id={ id + "-tab" } data-bs-toggle="tab" data-bs-target={ "#" + id } type="button" role="tab">
                    { (icon || icon2) && (
                        <span className="icon-wrap">
                            { icon && <i className={ icon }></i> }
                            { icon2 && <i className={ icon2 }></i> }
                        </span>
                    )}
                    { title }
                </button>
            );
        },
    });
}