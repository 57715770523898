import functions from '../functions';
import { details } from './details';

const pricelistCalculation = {
    $form: null,
    params: {},
    defaults: {
        guests: '2,0',
    },
    init($modalTarget) {

        this.$form = $modalTarget.find('.pricelist-form');

        if(! this.$form.length) return;

        this.getParams();

        if(this.params.guests) {
            const $guests = $modalTarget.find('[name=guests]');
            $guests.val(this.params.guests).trigger('change');
        }

        this.calculation($modalTarget.find('.nav-link.active'));

        $modalTarget.find('.nav-link').on('shown.bs.tab', e => {
            const $tabLinkTarget = $(e.currentTarget);

            const tabTargetId = $tabLinkTarget.attr('data-bs-target').replace('#', '');
            const $tabTarget = $('.tab-pane[id="' + tabTargetId + '"]');
            const $pricelistCalculation = $tabTarget.find('.pricelist-calculation');
            const isCalculated = +$pricelistCalculation.attr('data-calculation');

            ! isCalculated && this.calculation($tabLinkTarget);

        });

        this.$form.on('change', e => {
            e.preventDefault();
            e.stopPropagation();

            const $target = $(e.currentTarget);
            const data = functions.getFormData($target);

            this.params.guests = data.guests;
            this.params.serviceCode = data.serviceCode;

            $('.pricelist-calculation').attr('data-calculation', 0);

            this.calculation($modalTarget.find('.nav-link.active'));
        });
        this.$form.on('submit', e => {
            e.preventDefault();
            e.stopPropagation();

            const $target = $(e.currentTarget);
            $target.trigger('change');
        });
    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        this.params.dateFrom = null; 
        this.params.dateTo = null; 
        this.params.guests = this.params.guests && this.params.guests.startsWith('0') ? null : this.params.guests;
    },
    calculation($tabLinkTarget) {

        const tabTargetId = $tabLinkTarget.attr('data-bs-target').replace('#', '');
        const $tabTarget = $('.tab-pane[id="' + tabTargetId + '"]');

        const $pricelistCalculation = $tabTarget.find('.pricelist-calculation');
        const pricelistParams = $tabLinkTarget.attr('data-pricelistParams') ? JSON.parse($tabLinkTarget.attr('data-pricelistParams')) : null;
        this.params = $.extend({}, this.params, pricelistParams);
        this.params.calcPeriods = $tabLinkTarget.attr('data-calcPeriods') ? $tabLinkTarget.attr('data-calcPeriods') : null;

        const $pricelistCalculationSpinner = $tabTarget.find('.pricelist-calculation-spinner');
        $pricelistCalculation.addClass('d-none');
        $pricelistCalculationSpinner.removeClass('d-none');

        if(this.params.calcPeriods
            && this.params.guests
            && this.params.objectGroupId
            && this.params.partnerId
            && this.params.publicId
            && this.params.view) {
            $.get('/services/get_pricelists_calculation/', this.params).done(response => {
                if(response) {
                    $pricelistCalculation.html(response);
                    $pricelistCalculation.attr('data-calculation', 1);
                    $pricelistCalculation.removeClass('d-none');
                } else {
                    $pricelistCalculation.html('<div class="pricelist-no-calc">Nema kalkulacije za odabrane parametre.</div>');
                    $pricelistCalculation.removeClass('d-none');
                }
    
                $pricelistCalculationSpinner.addClass('d-none');
            }).fail(error => {
                console.log(error);
            });
        } else {
            $pricelistCalculationSpinner.addClass('d-none');
            $pricelistCalculation.html('<div class="pricelist-no-calc">Odaberite broj putnika za tačan proračun cene.</div>');
            $pricelistCalculation.removeClass('d-none');
        }

    },
};

export {
	pricelistCalculation
};