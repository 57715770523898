if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;

    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = [
        'custom-gutenberg/card-banner-horizontal',
        'custom-gutenberg/card-banner-large',
        'custom-gutenberg/card-banner',
        'custom-gutenberg/card-destination',
        'custom-gutenberg/card-page',
    ];

    registerBlockType('custom-gutenberg/pages-slide', {
        // built-in attributes
        title: 'Pages Slide',
        description: 'Custom Pages Slide',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit() {

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/card-destination', {} ],
            ];

            return (
                <div class="keen-slider__slide">
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                </div>
            );
        },

        save() {

            return (
                <div class="keen-slider__slide">
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}