import functions from '../functions';

let id = 0;
const instance = {};
const $deatils = $('#details-datepicker');
const deatils = $('#details-datepicker')[0];

class DeparturePicker {
    constructor(elem, departures = null) {
        this.id = ++id;
        instance[this.id] = this;
        this.params = {};

        this.$elem = $(elem);
        this.$elem._flatpickr = flatpickr(elem, {
            altInput: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            closeOnSelect: false,
            disableMobile: true,
            onOpen: (selected, dateStr, instance) => {
                instance.set('minDate', this.firstDate)
                if(dateStr) {
                    this.setDurationPicker(this.departures, selected, dateStr, instance);
                }
            },
            onClose: (selected, dateStr, instance) => {
                instance.setDate(dateStr);
  
                this.$elem.parents('form').trigger('change');
            },
            onChange: (selected, dateStr, instance) => {
                $('[name="aDateTo"]').val('');
                $('[name="aDateFrom"]').val('');
                if(dateStr === '') {
                    $('[name="dateTo"]').val('');
                }
                this.setDurationPicker(this.departures, selected, dateStr, instance);

                const $target = $('.duration-item.active');
                let time = $target.attr('data-time');
                let aDateFrom = $target.attr('data-adatefrom');
                let aDateTo = $target.attr('data-adateto');

                $('[name="time"]').val(time);
                $('[name="aDateFrom"]').val(aDateFrom);
                $('[name="aDateTo"]').val(aDateTo);
            },
            onReady: (dateObj, dateStr, instance) => {
                const $container = $(instance.calendarContainer);
                $container.append(this.printDurationPickerContainer());

                $(instance.element).change(e => {
                    if(e.originalEvent) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
            },
        });
        if(deatils) {
            this.$elem._flatpickr.set('clickOpens', true);
        } else {
            this.$elem._flatpickr.set('clickOpens', false);
        }

        ! $('[name="destination"]')[0] && this.setDepartures();
        // if (departures) this.departures = departures;

        this.getParams();
        $('[name="dateTo"]').val(this.params.dateTo);
        $('[name="time"]').val(this.params.time || '');

        $('.datepicker [data-clear]').on('click', e => {
            this.$elem._flatpickr.clear();
            this.$elem._flatpickr.set('minDate', this.firstDate);
        });

        $(this.$elem._flatpickr.altInput).on('click', e => {

            const $formGuide = this.$elem.parents('.search-form').find('.form-guide');
            const formGuide = $formGuide[0];

            if(formGuide && ! this.$elem._flatpickr.config.clickOpens) {
               setTimeout(() => {
                    formGuide._formGuide.show();
                });
            }

        });

        $(this.$elem.parents('.form-field')).on('click', e => {

            const $formGuide = this.$elem.parents('.search-form').find('.form-guide');
            const formGuide = $formGuide[0];

            if(formGuide && ! this.$elem._flatpickr.config.clickOpens) {
               setTimeout(() => {
                    formGuide._formGuide.show();
                });
            }
            else {
                this.$elem._flatpickr.open();
            }

        });

        $('[name="destination"]').on('change', e => {
            const $target = $(e.currentTarget);
            this.params.destination = $target.val();
            this.isFormElem($target) && this.setDepartures();
        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            const isFormElem = this.isFormElem($(e.currentTarget));
            isFormElem && this.$elem._flatpickr.clear();
            isFormElem && this.$elem._flatpickr.set('clickOpens', false);
        });
        
        $(window).on('hashchange', e => {
            //this.setDepartures();
        });
    }

    isFormElem($target) {
        this.$form = $target.parents('.search-form');
        return this.$form.has(this.$elem).length;
    }

    printDurationPickerContainer() {
        return `
            <div class="duration-picker text-start border-top border-primary px-3 py-2">
                <div class="fw-bold text-uppercase mb-1">Trajanje putovanja</div>
                <div class="choose-label text-danger">Izaberite datum sa kalendara</div>
                <div class="row d-none"></div>
            </div>
        `;
    }

    printDurationItem(departure, index) {

        return `
            <div class="col-auto">
                <button class="duration-item btn btn-sm btn-outline-primary fw-bold mb-1"
                    data-dateFrom="${ departure.dateFrom }"
                    data-dateTo="${ departure.dateTo }"
                    data-aDateFrom="${ departure.aDateFrom }"
                    data-aDateTo="${ departure.aDateTo }"
                    data-time="${ departure.time }"
                    data-index="${ index }">
                    ${ departure.nights } noćenja | Povratak: ${ functions.formatDate(departure.dateTo) }
                </button>
            </div>
        `;
    }

    getParams() {
        //this.params = functions.getUrlData(true);
        this.params = $.extend({}, this.params, functions.getUrlData(true));
    }

    setDepartures() {
        ! this.params.destination && this.getParams();

        if(deatils) {
            this.params.destination = $deatils.attr('data-countryid');
        }
        
        let params = this.params;
        params.countryId = params.destination && params.destination.split(':')[0];
        params.regionId = params.destination && params.destination.split(':')[1] ? params.destination.split(':')[1] : null;
        params.rivieraId = params.destination && params.destination.split(':')[2] ? params.destination.split(':')[2] : null;
        params.placeId = params.destination && params.destination.split(':')[3] ? params.destination.split(':')[3] : null;

        let dp = this.$elem.closest('.form-guide-container').attr('data-dynamicpackage')
        if (dp) params.dynamicPackage = dp;

        const getDepartures = this.getDepartures(params);
        
        if(getDepartures) {
            getDepartures.done(response => {
                this.departures = response;

                this.departures.sort((a, b) => {
                    return new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime() || a.duration - b.duration;
                });

                this.$elem._flatpickr.set('enable', this.getEnableDates(this.departures));
                this.$elem._flatpickr.set('clickOpens', true);

                this.firstDate = this.getEnableDates(this.departures)[0] ? this.getEnableDates(this.departures)[0] : null;
                this.$elem._flatpickr.set('minDate', this.firstDate);

            }).fail(error => {
                console.log(error);
            });
        } else if(deatils && $deatils.attr('data-departures')) {
            this.departures = JSON.parse($deatils.attr('data-departures'));
            this.$elem._flatpickr.set('enable', this.getEnableDates(this.departures));
            this.$elem._flatpickr.set('clickOpens', true);
        } else {
            this.$elem._flatpickr.set('clickOpens', false);
        }

    }

    getDepartures(params) {
        if(params.countryId) {
            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/get_departures/'
                + '?countryId=' + params.countryId
                + (params.placeId ? '&placeId=' + params.placeId : '')
                + (params.dynamicPackage ? '&dynamicPackage=' + params.dynamicPackage : '')
                + (params.regionId ? '&regionId=' + params.regionId : '')
                + (params.rivieraId ? '&rivieraId=' + params.rivieraId : '')
            );
        }
        return null;
    }

    getEnableDates(departures) {
        let enable = [];
        if(departures) {
            Object.entries(departures).forEach(item => {
                const departure = item[1];
                enable.push(departure.dateFrom);
            });
            enable = enable.filter((item, index, array) => {
                return array.indexOf(item) === index;
            });
        }
        return enable;
    }

    setDurationPicker(departures, selected, dateStr, instance) {

        const $container = $(instance.calendarContainer);
        const $durationPicker = $container.find('.duration-picker');
        const $row = $durationPicker.find('.row');
        const $chooseLabel = $durationPicker.find('.choose-label');

        $durationPicker.find('.col-auto').remove();
        if(departures.length) {

            $row.removeClass('d-none');
            $chooseLabel.addClass('d-none');

            let index = 0;

            departures.forEach(departure => {
                if(departure.dateFrom == dateStr) {
                    $row.append(this.printDurationItem(departure, index));

                    $('.duration-item').removeClass('active');
                    if(this.params.dateTo && this.params.time && $('.duration-item[data-dateTo="' + this.params.dateTo + '"][data-time="' + this.params.time + '"]')[0]) {
                        $('.duration-item[data-dateTo="' + this.params.dateTo + '"][data-time="' + this.params.time + '"]').addClass('active');
                        $('[name="dateTo"]').val(this.params.dateTo);
                        $('[name="time"]').val(this.params.time);
                    } else {
                        $('.duration-item[data-index="0"]').addClass('active');
                        index == 0 && $('[name="dateTo"]').val(departure.dateTo);
                        index == 0 && $('[name="time"]').val(departure.time);

                    }

                    index++;
                }
            });

            $('.duration-item').on('click', e => {
                e.preventDefault();
                e.stopPropagation();
                $('.duration-item').removeClass('active');
                const $target = $(e.currentTarget);
                let dateTo = $target.attr('data-dateTo');
                let time = $target.attr('data-time');
                let aDateFrom = $target.attr('data-adatefrom');
                let aDateTo = $target.attr('data-adateto');

                $target.addClass('active');
                this.params.dateTo = dateTo;
                this.params.time = time;
                $('[name="dateTo"]').val(dateTo);
                $('[name="time"]').val(time);
                $('[name="aDateFrom"]').val(aDateFrom);
                $('[name="aDateTo"]').val(aDateTo);

                $('[name="dateTo"]').trigger('change');
                instance.close();
            });

            $('[name="dateTo"]').on('change', e => {
                const $target = $(e.currentTarget);
                $('.duration-item[data-dateTo="' + $target.val() + '"][data-time="' + $('[name="time"]').val() + '"]').addClass('active');
            });
        }

    }
}

export {
	DeparturePicker
};