import functions from '../functions';
import lozad from 'lozad'
import pagination from '../components/pagination';


$(() => {
    listFavorites.init();
});

const listFavorites = {
    defaults: {
        perPage: 9,
        template: 'favorites'
    },
    init() {
        $(document).on('click tap', '.add-favourites', function(e) {
            e.preventDefault();

            let $elem = $(e.currentTarget);
            let cFavs = functions.getCookie('user_favourites');

            let groupId = $elem.attr('data-objectgroupid');
            let dynamicPackage = $elem.attr('data-dynamicpackage') ? $elem.attr('data-dynamicpackage') : 0;
            let unitId = $elem.attr('data-unitid');

            let dpKey = groupId + '_dp_' + dynamicPackage;

            cFavs = cFavs ? JSON.parse(cFavs) : {};

            if (! cFavs[dpKey]) cFavs[dpKey] = [];

            if (cFavs[dpKey].includes(unitId))
            {
                cFavs[dpKey] = cFavs[dpKey].filter((value, index, array) => value != unitId );
            }
            else
            {
                cFavs[dpKey].push(unitId);
            }

            cFavs[dpKey] =  cFavs[dpKey].filter((value, index, array) => array.indexOf(value) === index);

            $elem.toggleClass('active');
            
            functions.setCookie('user_favourites', JSON.stringify(cFavs));

            functions.favoritesLabel(JSON.stringify(cFavs));
        })

        const favoritesObserver = lozad('.list-favorites', {
            rootMargin: '200px 200px',
            loaded: el => {
                let elem = $(el);
                // if ( !this.$listItems.attr('data-loaded') ) {
                    let params = this.getParams(elem);

                    this.find(params, elem);
                // }
               
                $(el).triggerHandler('lozadLoaded');
            }
        });
        favoritesObserver.observe();
    },

    find(params, currItem) {
        if (! params.unitIds || ! (params.unitIds && params.unitIds.length) ) params.unitIds = [1];
        // {
        //     currItem.remove();
        //     return;
        // }

        $.get('/services/list/', params).then((response) => {
            this.findDone(response, currItem, params);
            // LOZAD
            window.observer.observe();
        });
    },

    findDone(response, currItem, params) {
        response = JSON.parse(response);          

        // this.unsetLoadingMode();

        this.total = response.total;
        let currentList = currItem || this.$listItems;
        
        currentList.html(response.html).attr('data-loaded', 1);

        params.total = response.total;

        functions.markFavorites()

        this.initComponents();

        if (params.total != 0) {
            if(! currItem.find('.paginate')[0]) currItem.append('<div class="paginate"></div>');

            currItem.find('.paginate').html(pagination({
                currentPage: params._page,
                total: params.total,
                perPage: params.perPage,
            }));
        }

    },
    initComponents() {
     
        $(document).on('click', '[data-page]', function(e) {
            e.preventDefault();
            listFavorites.paginate($(e.currentTarget));
        });
    },
    paginate($elem) {
        let $list = $elem.closest('.list-favorites');
        let page = +$elem.attr('data-page');
        if (page != +$list.attr('data-page')) {

            $list.attr('data-page', page);

            let params = this.getParams($list);
            this.find(params, $list);
        }
    },

    getParams(currItem) {
        let currentList = currItem;
        let params = {};
        params.dynamicPackage = currentList.attr('data-dynamicpackage');
        params.objectGroupId = currentList.attr('data-objectgroupid');
        params.template = this.defaults.template;
        params.perPage = currentList.attr('data-perPage') || this.defaults.perPage;
        // params.paginate = currentList.attr('data-paginate') || this._defaults.paginate;
        // params.hasArrival = currentList.attr('data-hasarrival') || '';
        params._page = currentList.attr('data-page') || 1;
        let key = currentList.attr('data-key') || '';

        let cFavs = functions.getCookie('user_favourites');
        cFavs = cFavs ? JSON.parse(cFavs) : {};

        params.unitIds = cFavs[key];
        return params;
    },
    
}