if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/hero-slide', {
        // built-in attributes
        title: 'Hero Slide',
        description: 'Custom Hero Slide',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'POGLEDAJ PONUDU'
            },
            btnText: {
                type: 'string',
                default: 'Saznajte više'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                viewMore,
                btnText
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            function onBtnTextChange(btnText) {
                setAttributes({btnText: btnText});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Link' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                    <PanelBody title={ 'Background Image Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ backgroundImage } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                    <PanelBody title={ 'Slide Content' }>
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <p><strong>Description</strong></p>
                        <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                        <p><strong>Sub text</strong></p>
                        <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>
                        <p><strong>Button</strong></p>
                        <TextControl value={ btnText } onChange={ onBtnTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="keen-slider__slide">
                    <div class="cover-image" style={{ height: 350, backgroundImage: `url(${backgroundImage})` }}>
                        <div class="container">
                            <a class="content">
                                <RichText 
                                    key="editable" 
                                    tagName="div" 
                                    className="description"
                                    placeholder="DESCRIPTION" 
                                    value={ description }
                                    onChange={ onDescriptionChange }/>
                                <RichText 
                                    key="editable" 
                                    tagName="h2" 
                                    className="title"
                                    placeholder="Title" 
                                    value={ title }
                                    onChange={ onTitleChange } />
                                <div class="cta">
                                    <RichText 
                                        key="editable" 
                                        tagName="div" 
                                        className="cta-content"
                                        placeholder="CONTENT" 
                                        value={ viewMore }
                                        onChange={ onViewMoreChange }/>
                                    <RichText 
                                        key="editable" 
                                        tagName="div" 
                                        className="cta-content-btn"
                                        placeholder="Saznajte više" 
                                        value={ btnText }
                                        onChange={ onBtnTextChange }/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                viewMore,
                btnText
            } = attributes;

            return (
                <div class="keen-slider__slide" >
                    <div class="lozad cover-image" data-background-image={ backgroundImage }>
                        <div class="container">
                            <a href={ link } class="content">
                                <div class="description">{ description }</div>
                                <h2 class="title">{ title }</h2>
                                <div class="cta">
                                    <div class="cta-content">{ viewMore }</div>
                                    <button class="btn cta-button">
                                        { btnText }
                                    </button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );
        },
    });
}