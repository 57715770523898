import './details/details';
import './details/details-package';
import './details/details-accommodation';
import './details/details-travel';

$(() => {

    $('.btn-share').on('click', e => {
        const $target = $(e.currentTarget);
        const $shareWrapper = $target.parents('.share-wrapper');
        let shareShown = ! $shareWrapper.hasClass('show');
        $shareWrapper.toggleClass('show', shareShown);
    });
    
    $('[data-bs-toggle="modal"][data-bs-tab]').on('click', e => {
        const $target = $(e.currentTarget);
        const unitId = $target.attr('data-unitid');
        const $tab = $('#unitModal-' + unitId).find('[id=' + $target.attr('data-bs-tab') + ']');
        $tab.tab('show');
    });

    $('.summary-large-show').on('click', e => {
        const $target = $(e.currentTarget);
        const $summary = $('.summary-large');
        const isShowen = $summary.hasClass('show');
        $summary.toggleClass('show', ! isShowen);
        $target.text(isShowen ? $target.attr('data-show') : $target.attr('data-hide'));
    });

    $('.see-on-map').on('click', e => {
        const $target = $(e.currentTarget);

        let modalTitle = $target.parent().find('.title-for-modal').text();
        $('#meetPointMap').find('.modal-title').text(modalTitle);

        let latLng = $target.attr('data-latlng');
        let $mpMap = $('#meetPointMap').find('.metting-point-map');
        let mapLink = $mpMap.attr('data-src').split('q=')[0];
        mapLink += 'q=' + latLng;
        $mpMap.attr('src', mapLink);
        $mpMap.attr('data-src', mapLink);

    });

    /*const $detailsNav = $('#details-nav');

    $(window).on('scroll', e => {
        $detailsNav.toggleClass('show', window.scrollY > 200);
    });*/

    const $table = $('.table-responsive');
    const tableURL = $table.length ? $table.attr('data-tableurl') : null;

    if(tableURL) {
        let html = '';
        $.get(tableURL).done(response => {
            html = $(response).find('.entry-content').html();
            $table.html(html);
        });
    }

    const programURL = $('[data-programurl]').length ? $('[data-programurl]').attr('data-programurl') : null;

    if(programURL) {
        let html = '';
        $.get(programURL).done(response => {
            html = $(response).find('.entry-content').html();
            if (html.trim())
            {
                $('#travelProgramModal').find('.modal-body').html(html);
                $('.travel-program-link').removeClass('d-none');
            }
            else
            {
                $('.travel-program-link').addClass('d-none');
            }
            
        }).fail(e => {
            //console.log(e);
            $('.travel-program-link').addClass('d-none');
        });
    }

});