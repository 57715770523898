import './blocks/cards/card-page';
import './blocks/cards/card-banner';
import './blocks/cards/card-banner-large';
import './blocks/cards/card-banner-horizontal';
import './blocks/cards/card-destination';

import './blocks/containers/container';
import './blocks/containers/row';
import './blocks/containers/column';

import './blocks/content/hero-slider';
import './blocks/content/hero-slide';

import './blocks/content/pages-slider';
import './blocks/content/pages-slide';

import './blocks/content/button-outline';
import './blocks/containers/color';

//import './blocks/content/special-popup';

import './blocks/tabs/nav-tabs';
import './blocks/tabs/tab-button';
import './blocks/tabs/tab-content';
import './blocks/tabs/tab-pane';
